$(window).resize(function () { 
    boxCategoriesBlog();
});

$(window).load(function () { 
    boxCategoriesBlog();
});

$(function () {
    boxCategoriesBlog();

    /**
     * Abrir/Fechar modal do curso
     */
    $('.bt-box-categories').on('click', function(e){
        e.preventDefault();
        
        $(this).show().find("i").toggleClass("fa-angle-down").toggleClass("fa-angle-up");
        $(this).closest(".row").next().slideToggle();
    });
});

function boxCategoriesBlog() {
    var width = $(window).width();
    if (width >= 992) {
        $('.bt-box-categories').hide();
        $('.bt-box-categories').closest(".row").next().slideDown();
    }else{
        $('.bt-box-categories').show().find("i").removeClass("fa-angle-down").addClass("fa-angle-up");
        $('.bt-box-categories').closest(".row").next().slideUp();
    }
}