$(document).ready(function(){
    $('#modal-login').on('show.bs.modal', function (e) {
        var $relatedTarget = $(e.relatedTarget),
            actionNext = $relatedTarget.attr("data-next");
        actionNext = (actionNext == "href")? $relatedTarget.attr("href"):actionNext;

        $("#action-next").val(actionNext);
    });
});

$(window).load(function() {
    //Verificando se é para abrir algum modal
    var x = location.hash;
    if (x) {
        if (strstr(x, "#modal-curso-") !== false) {
            var course = x.substr(13);
            $("a.insc-curso[data-course="+course+"]").click();
        }else if (x === "#modal-payment") {
            $("#modal-payment").modal({show:true});
        }
    }
});