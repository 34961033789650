$(window).on('load', function () {

    $('[data-toggle="tooltip"]').tooltip();

    $('.roll').bind('click',function(rolar) {
        rolar.preventDefault();
        var ancora = $(this);
        $('html, body').stop().animate({
            scrollTop: ($(ancora.attr('href')).offset().top)
        }, 1200, 'easeInOutExpo');
    });

    $('.owl-carousel').not(".depoimentos").owlCarousel({
        nav:true,
        loop:true,
        margin:10,
        responsiveClass:true,
        navText: ['<i class="fas fa-chevron-circle-left"></i>','<i class="fas fa-chevron-circle-right"></i>'],
        responsive:{
            0:{
                slideBy: 1,
                items:1,
                nav:true
            },
            600:{
                slideBy: 2,
                items:2,
                nav:true
            },
            1000:{
                slideBy: 3,
                items:3,
                nav:true,
                loop:false
            }
        }
    });

    //Carrosel dos vídeos de depoimentos
    $('.owl-carousel').owlCarousel({
        loop:true,
        margin:10,
        responsiveClass:true,
        dots: true,
        responsive:{
            0:{
                items:2,
                nav:false,
                loop:false
            },
    
            600:{
                items:3,
                nav:false,
                loop:false
            },
    
            1000:{
                items:4,
                nav:false,
                loop:false
            },
    
            1400:{
                items:5,
                nav:false,
                loop:false
            },
    
            1700:{
                items:7,
                nav:false,
                loop:false
            }
        }
    });

    $('[data-fancybox="gallery"]').fancybox({
        // Options will go here
    });

    alturaElement(".owl-carousel h2.altura");
    alturaElement(".section-cursos-novos h2.altura");
    alturaElement(".section-depoimentos article.altura");
    alturaElement(".page-como-funciona div.altura");
    alturaElement(".page-galeria div.altura");

    $(".login-remenber").on("click", function () {
        $(this).closest(".modal-dialog").find(".modal-content").toggleClass("d-none");
    });

    $("#change-pass").on('change', function(e) {
        var changePass = $(this).prop("checked");

        $(this).parent().toggleClass("mb-5").toggleClass("mb-3").next().slideToggle();
        $("#password").val("").prop("required", changePass);
        $("#cpassword").val("").prop("required", changePass);
    });

    /** Abri e fechar menu bootstrap */
    $('body').on('mouseover mouseout', '.dropdown', function(e) {
        $(e.target).dropdown('toggle');
    });

    /** Link menu pai bootstrap */
    $(".dropdown a").on("click", function() {
        var href = $(this).attr("href");
        if ($.trim(href) !== "") {
            window.location.href = href;
        }
    });

    /** Mudar Depoimentos */
    $('.ctl-index #myDepoimentos a').on('click', function (e) {
        e.preventDefault();
        $(this).removeClass("active").removeClass("show");
        $(this).tab('show');
    
        $('.tab-pane iframe').each(function(index) {
            $(this)[0].contentWindow.postMessage('{"event":"command","func":"' + 'pauseVideo' + '","args":""}', '*');    
        });
    });
    
    // setInterval(function() {
    //     alturaElement(".owl-carousel h2.altura");

    //     console.log("asasa");
    // }, 1000);
});

$(window).resize(function () { 
    alturaElement(".owl-carousel h2.altura");
    alturaElement(".section-cursos-novos h2.altura");
    alturaElement(".section-depoimentos article.altura");
    alturaElement(".page-como-funciona div.altura");
    alturaElement(".page-galeria div.altura");
});

$(document).on('ready', function () {
    alturaElement(".owl-carousel h2.altura");
    alturaElement(".section-cursos-novos h2.altura");
    alturaElement(".section-depoimentos article.altura");
    alturaElement(".page-como-funciona div.altura");
    alturaElement(".page-galeria div.altura");
});

function alturaElement (element) {
    altura = 0;
    $.each($(element), function() {
        if ($(this).height() > altura) {
            altura = $(this).height();
        }
    });
    $(element).css("min-height", altura+"px");
}