$(document).ready(function(){
    /**
     * Abrir modal do curso
     */
    $('.insc-curso').on('click', function(e){
        e.preventDefault();
        
        var course = $(this).attr('data-course');
        $("#loading").show();
        $.post(URL_BASE+"cursos/modal", {course:course}, function(data) {
            $("#loading").hide();
            if (data!=="false") {
                $('#modal-form-insc').find('.modal-content').html(data);
                $('#modal-form-insc').modal({show:true});
            }else{
                swal({
					title: "Erro",
					text: "Não conseguimos concluir, algo deu errado. Tente novamente depois.",
					type: "warning",
					html: true
				});
            }
        });
    });
    
    /**
     * Marcar interesse nos cursos
     */
    $(".check-interested").on('click', function(e) {
        if (isLogin === "false") {
            $('#modal-login').modal({show:true});
            $(this).prop("checked", false);
        }else{
            var course = $(this).val(),
                add = $(this).prop("checked");

            $("#loading").show();
            $.post(URL_BASE+"novos-cursos/interested", {course:course, add:add}, function(data) {
                $("#loading").hide();
                if (data!=="false") {
                    swal({
                        title: "Sucesso",
                        text: data,
                        type: "success",
                        html: true
                    }); 
                }else{
                    swal({
                        title: "Erro",
                        text: "Não conseguimos concluir, algo deu errado. Tente novamente depois.",
                        type: "warning",
                        html: true
                    });
                }
            });
        }
    });
});